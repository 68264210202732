/* custom styles for the datepickers used by CalendarPicker component*/

.react-datepicker {
  border-radius: 0;
  font-family: "TuiTypeLite", sans-serif;
  font-size: 1rem;
  background-color: #fff;
  color: #222;
  border: 1px solid #aeaeae;

  /*prevent user from selecting text in datepicker*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.react-datepicker__current-month {
  text-transform: uppercase;
}

.react-datepicker__day--today {
  background-color: #e2f3fe;
  border-radius: 0;
  color: #70cbf4;
}

.react-datepicker__day--selected {
  background-color: #70cbf4;
  border-radius: 0;
  color: white;
}

.react-datepicker__header {
  border: none;
  background-color: white;
}

.react-datepicker__navigation {
  outline: none !important;
  border-color: #70cbf4;
}

.react-datepicker__navigation--next:hover *::before {
  border-color: #70cbf4;
}

.react-datepicker__navigation-icon::before {
  border-color: #70cbf4;
}

.react-datepicker__navigation--previous {
  border-color: #70cbf4;
}

.react-datepicker__navigation--previous:hover *::before {
  border-color: #70cbf4;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  left: 5px;
}

.react-datepicker__month-year-read-view {
  visibility: visible !important;
}

.react-datepicker__current-month--hasMonthYearDropdown {
  display: none;
}

.react-datepicker__month-year-read-view--down-arrow {
  top: 4px;
  border-color: #70cbf4;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles */
  .react-datepicker__month-year-read-view--down-arrow {
    left: 20px;
  }
}

.react-datepicker__month-year-dropdown {
  width: 60%;
  left: 20%;
  max-height: 321px;
  overflow: auto;
}
.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 100%;
}
