.excursion-attribute {
  line-height: 1.5;
}

.excursion-attribute::before {
  content: "■";
  color: #092a5e;
  padding-right: 6px;
  vertical-align: 5%;
}

.excursion-filters-container {
  background: linear-gradient(90deg, #219ed9 50%, #70cbf4 50%);
}
